// Navbar.jsx

import React, { useState } from "react";
import "../../styles.css";
import "react-awesome-button/dist/styles.css";
import logo2 from "../../Assets/MainAssets/logo_brown-03.png"; // Update the path if necessary

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const top =
        section.getBoundingClientRect().top + window.pageYOffset - 100; // Offset -100 pixels
      window.scrollTo({ top, behavior: "smooth" });
    }
    // Close the menu after clicking on a link
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <a href="/" className="navbar-logo">
        <img src={logo2} alt="Digitomy Logo" />
      </a>
      <div
        className="navbar-menu-icon"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className={`menu-icon ${isMenuOpen ? "open" : ""}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`navbar-links ${isMenuOpen ? "active" : ""}`}>
        {["About", "Demo", "Team", "Collaborators", "Patents", "Contact"].map(
          (text) => (
            <button
              key={text}
              onClick={(e) => handleScroll(e, text.toLowerCase())}
              style={{
                background: "none",
                border: "none",
                color: "black", // Set text color to black
                margin: "0 20px",
                textDecoration: "none",
                transition: "color 0.3s, border-bottom 0.3s",
                position: "relative",
                cursor: "pointer",
                padding: "10px 0",
                fontSize: "16px",
                fontFamily: "inherit",
              }}
            >
              {text}
            </button>
          )
        )}
      </div>
    </nav>
  );
};

export default Navbar;
